import { useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import dynamic from 'next/dynamic';
import cn from 'classnames';
import { useTranslation } from 'hooks';
import { LanguageContext } from 'context/Language';
import DictionaryContext from 'context/Dictionary/DictionaryContext';
import { articles, v1_jsonString } from 'api';
import { cacheTime } from 'utils';
import { BaseProps, ArticleTags } from 'types';
import {
  LogotypeFooter,
  SelectDownArrow,
  SocialFacebook,
  SocialInstagram,
  SocialLinkedIn,
  SocialYouTube,
  SocialTikTok,
  UnionLogotype,
  LogotypeFooterMobile,
} from 'resources';

const Container = dynamic(() => import('components/Grid/Container'));
const Icon = dynamic(() => import('components/Icon'));
const LanguageSwitcher = dynamic(() => import('components/LanguageSwitcher'));
const Link = dynamic(() => import('components/Link'));
const Loader = dynamic(() => import('components/Loader/Inline'));
const Responsive = dynamic(() => import('components/Responsive'));
const Tooltip = dynamic(() => import('components/Tooltip'));

export interface FooterProps extends BaseProps {}

const Footer: React.FC<FooterProps> = () => {
  const { t } = useTranslation();
  const { lang } = useContext(LanguageContext);
  const { activeLanguages } = useContext(DictionaryContext);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [footerSecondaryMenu, setFooterSecondaryMenu] = useState([]);

  const { data, isLoading } = useQuery(
    ['menu-footer', { page_size: 100, tags: ArticleTags.MENU_FOOTER }],
    articles.getList,
    {
      cacheTime,
    },
  );

  const { data: dataSettings } = useQuery(['settings'], v1_jsonString.getList, {
    cacheTime,
  });

  const { data: dataPopular, isLoading: isLoadingDataPopular } = useQuery(
    ['menu-popular', { page_size: 100, tags: ArticleTags.POPULAR_PAGE }],
    articles.getList,
    {
      cacheTime,
    },
  );

  useEffect(() => {
    const data = dataSettings?.results?.find((i) => i.keyword === 'footerUrl');
    if (data) {
      try {
        setFooterSecondaryMenu(JSON.parse(data.value));
      } catch (e) {
        console.warn(e.message);
      }
    }
  }, [dataSettings]);

  const iconsName = {
    facebook: 'facebook',
    instagram: 'instagram',
    youTube: 'youTube',
    linkedIn: 'linkedIn',
    tikTok: 'tikTok',
  };

  const socialListIcons = useMemo(
    () => (
      <ul className="social-icon__list">
        {iconsName.facebook !== t('footer.facebook') && (
          <li className="social-icon__item">
            <Link className="social-icon__link" href={t('footer.facebook')} isTarget>
              <Icon component={SocialFacebook} />
            </Link>
          </li>
        )}
        {iconsName.instagram !== t('footer.instagram') && (
          <li className="social-icon__item">
            <Link className="social-icon__link" href={t('footer.instagram')} isTarget>
              <Icon component={SocialInstagram} />
            </Link>
          </li>
        )}
        {iconsName.youTube !== t('footer.youTube') && (
          <li className="social-icon__item">
            <Link className="social-icon__link" href={t('footer.youTube')} isTarget>
              <Icon component={SocialYouTube} />
            </Link>
          </li>
        )}
        {iconsName.linkedIn !== t('footer.linkedIn') && (
          <li className="social-icon__item">
            <Link className="social-icon__link" href={t('footer.linkedIn')} isTarget>
              <Icon component={SocialLinkedIn} />
            </Link>
          </li>
        )}
        {iconsName.tikTok !== t('footer.tikTok') && (
          <li className="social-icon__item">
            <Link className="social-icon__link" href={t('footer.tikTok')} isTarget>
              <Icon component={SocialTikTok} />
            </Link>
          </li>
        )}
      </ul>
    ),
    [t],
  );

  const navigationLinkBox = useMemo(
    () => (
      <ul className="right-block__list">
        <h3 className="center-block__title">{t('footer.footerCustomerSupport')}</h3>

        {footerSecondaryMenu.map((item, key) => (
          <li className="right-block__item" key={key}>
            <Link href={item[lang]?.url}>{item[lang]?.title}</Link>
          </li>
        ))}
        <li className="right-block__item">
          <Link href={`/${lang}/sitemap`}>{t('footer.footerSiteMLinkp')}</Link>
        </li>
      </ul>
    ),
    [t],
  );

  return (
    <div className="layout__footer">
      <div className="footer-container">
        <Container>
          <Responsive until="small-desktop">
            <div className="footer-container__upper-content">
              <div className="left-block">
                <div className="left-block__logotype">
                  <Icon component={LogotypeFooterMobile} />
                </div>
                <h3 className="left-block__title">{t('footer.footerTopTitle')}</h3>
                <p className="left-block__subtitle">{`${t('footer.footerCustomersSupport')}:`}</p>
                <p className="left-block__text">{t('footer.footerPhone')}</p>
                <p className="left-block__text">{t('footer.footerEmail')}</p>
                <div className="lower-text">
                  <p className="left-block__text">{t('footer.footerAddress')}</p>
                </div>

                {socialListIcons}
              </div>

              <div className="center-block">
                <div>
                  <h3 className="center-block__title">{t('footer.footerNavigation')}</h3>

                  {isLoading ? (
                    <Loader />
                  ) : (
                    <ul className="center-block__list">
                      {data?.results?.map(({ id, i18n, tags }) => (
                        <li className="center-block__item" key={id}>
                          <Link args={{ i18n, tags }}>{i18n[lang]?.title}</Link>
                        </li>
                      ))}
                    </ul>
                  )}

                  {navigationLinkBox}
                </div>

                <div className="navigation-list">
                  <h3 className="center-block__title">{t('footer.footerPopularLink')}</h3>

                  {isLoadingDataPopular ? (
                    <Loader />
                  ) : (
                    <ul className="center-block__list">
                      {dataPopular?.results?.map(({ id, i18n, tags }) => (
                        <li className="center-block__item" key={id}>
                          <Link args={{ i18n, tags }}>{i18n[lang]?.title}</Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </Responsive>
          <Responsive>
            <div className="footer-container__upper-content">
              <div className="left-block">
                <div className="left-block__logotype">
                  <Icon component={LogotypeFooter} />
                </div>
                <h3 className="left-block__title">{t('footer.footerTopTitle')}</h3>
                <p className="left-block__subtitle">{`${t('footer.footerCustomersSupport')}:`}</p>
                <p className="left-block__text">{t('footer.footerPhone')}</p>
                <p className="left-block__text">{t('footer.footerEmail')}</p>
                <div className="lower-text">
                  <p className="left-block__text">{t('footer.footerAddress')}</p>
                </div>
              </div>

              <div className="center-block">
                <h3 className="center-block__title">{t('footer.footerNavigation')}</h3>

                {isLoading ? (
                  <Loader />
                ) : (
                  <ul className="center-block__list">
                    {data?.results?.map(({ id, i18n, tags }) => (
                      <li className="center-block__item" key={id}>
                        <Link args={{ i18n, tags }}>{i18n[lang]?.title}</Link>
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              <div className="navigation-list">
                <h3 className="center-block__title">{t('footer.footerPopularLink')}</h3>

                {isLoadingDataPopular ? (
                  <Loader />
                ) : (
                  <ul className="center-block__list">
                    {dataPopular?.results?.map(({ id, i18n, tags }) => (
                      <li className="center-block__item" key={id}>
                        <Link args={{ i18n, tags }}>{i18n[lang]?.title}</Link>
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              <div className="right-block">
                <>
                  {navigationLinkBox}
                  {socialListIcons}
                </>
              </div>
            </div>
          </Responsive>

          <div className="footer-container__lower-content">
            <Responsive>
              <div className="left-content">
                <p className="left-content__text">{t('footer.copyright')}</p>
                <div className="space-point" />
                {activeLanguages?.length > 0 && (
                  <Tooltip
                    trigger="hover"
                    placement="right"
                    bodyClass="footer-language-switcher"
                    tooltip={<LanguageSwitcher />}
                    onVisibleChange={setTooltipVisible}
                  >
                    <div className="language-change">
                      <p className="language-change__text">{t(`header.${lang}`)}</p>
                      <Icon
                        className={cn('language-change__icon', { rotate: tooltipVisible })}
                        component={SelectDownArrow}
                      />
                    </div>
                  </Tooltip>
                )}
              </div>
            </Responsive>
            <div className="right-content">
              <div className="logo-box">
                <Icon component={UnionLogotype} />
              </div>
            </div>
            <Responsive until="small-desktop">
              <div className="left-content">
                <p className="left-content__text">{t('footer.copyright')}</p>
              </div>
            </Responsive>
          </div>
          <div className="footer-container__bottom-content">
            <div
              className="bottom-content__image"
              style={{ backgroundImage: 'url("/usaid.png")' }}
            />

            <div className="bottom-content__text-box">
              <p className="bottom-content__text">{t('footer.usaidText')}</p>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
